export function convertExtToMode(ext) {
    switch (ext.toString().toLowerCase()) {
        case 'java':
            return 'java'
        case 'js':
            return 'javascript'
        case 'html':
            return 'html'
        case 'css':
            return 'css'
        case 'sql':
            return 'sql'
        case 'json':
            return 'json'
        case 'py':
            return 'python'
        case 'rb':
            return 'json'
        case 'ruby':
            return 'json'
        case 'sass':
            return 'sass'
        case 'go':
            return 'golang'
        case 'cs':
            return 'csharp'
        case 'css':
            return 'css'
        case 'text':
            return 'txt'
        case 'tsx':
            return 'tsx'
        default:
            return 'txt'
    }
}

