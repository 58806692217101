import React, {useState} from 'react';
import styles from './NewEntryModal.module.css';
import {generateUniqueId, iterateJsonArray} from "../../common/utils";
import {useDispatch, useSelector} from "react-redux";
import {updateSelectedItem} from "../../redux/selectedItem";
import {addToActiveItems} from "../../redux/activeItems";
import {post} from "../../common/HttpUtils";
import {setStructureBody} from "../../redux/jsonStructure";
import _ from 'lodash'


const NewEntryModal = (props) => {

    const [localName, setLocalName] = useState('');
    const selectedItem = useSelector((state) => state.selectedItem);
    const activeItems = useSelector((state) => state.activeItems.items);
    const dispatch = useDispatch();
    const files = useSelector((state) => state.jsonStructure.body)
    const user = useSelector((state) => state.user);


    function handleChange(e) {
        setLocalName(e.target.value);
    }

    function handleChangeSubmit(e) {
        if (e.keyCode === 13) {
            handleChange(e);
        }
    }

    function saveNewEntry() {
        let tempEntryObj = Object.assign({}, props.entryObj);
        tempEntryObj.name = localName;
        tempEntryObj.id = String(generateUniqueId());
        if (tempEntryObj.type == 'folder') {
            tempEntryObj.items = [];
        }
        let copiedStructured = _.cloneDeep(props.files);

        props.setEntryObj(tempEntryObj);
        iterateJsonArray(copiedStructured, props.targetVal.id, tempEntryObj)

        dispatch(setStructureBody(copiedStructured))

        saveJsonStructure(copiedStructured);

        if (tempEntryObj.type == 'file') {
            let entryObj = createEntryObj(tempEntryObj)
            dispatch(updateSelectedItem(entryObj))
            addToActiveList(entryObj);
            setFileContent(entryObj)
        }

        console.log('Entry Input Field Reset')
        document.getElementById('entryInputField').value = ''

    }

    let entityType = props.entryObj.type == 'folder' ? 'Folder' : 'File'

    return (
        <div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className={`modal-header ${styles.modalHeader}`}>
                            <h5 className="modal-title" id="exampleModalLabel">New {entityType}</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className={`modal-body ${styles.modalBody}`}>
                            {/*<form id="entryInputFieldForm">*/}
                                <input type="text" className={`form-control ${styles.inputField}`} id="entryInputField"
                                       autoComplete='off' placeholder="Name" onChange={(e) => handleChange(e)}/>
                            {/*</form>*/}

                        </div>
                        <div className={`modal-footer ${styles.modalFooter}`}>
                            <button id='modal-save-btn' type="submit" className={`btn btn-primary ${styles.btnPrimary}`}
                                    data-bs-dismiss="modal"
                                    onClick={saveNewEntry}>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function setFileContent(obj) {

        let request = {
            id: obj.id,
            name: obj.name,
            content: obj.content,
            ext: obj.ext
        }
        let url = process.env.REACT_APP_SAVE_FILE_CONTENT_URL
        let response = post(url, request)
        console.log(response)
        // It is already in correct format, just save it into DB.
        // TODO Crete Post to updat;e File Need a new controller
        // post('','')
    }

    // Used for creation of an entry
    function saveJsonStructure(structure) {

        let url = process.env.REACT_APP_SAVE_JSON_STRUCTURE_URL;
        let request = {
            uniqueUserId: user.uniqueUserId,
            jsonStructure: JSON.stringify(structure)
        }
        post(url, request);
    }

    function addToActiveList(obj) {
        obj.active = true;
        dispatch(addToActiveItems(obj));
    }

    function createEntryObj(tempEntryObj) {
        let objNameArr = tempEntryObj.name.split('.');
        let objExt = 'text'
        let objName = tempEntryObj.name
        if (objNameArr.length > 1) {
            objExt = objNameArr[1];
            objName = objNameArr[0];
        }

        return {
            id: tempEntryObj.id,
            name: objName,
            type: 'file',
            content: '',
            ext: objExt
        };
    }
};

NewEntryModal.propTypes = {};

NewEntryModal.defaultProps = {};

export default NewEntryModal;
