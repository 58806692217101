import React, {useState} from 'react';
import styles from './TabContainer.module.css';
import Tab from "../Tab/Tab";
import {useSelector} from "react-redux";


const TabContainer = () => {
    const activeItems = useSelector((state) => state.activeItems.items);
    // const [localActive, setLocalActive] = useState(initActiveItem());


    return (
        <div className={styles.TabContainer}>

            {
                // If list is empty, just show empty div to prevent border from display
                // when tabs are not displayed
                activeItems.length > 0 ? <Tab/> : <div/>
            }
        </div>
    )


};


TabContainer.propTypes = {};

TabContainer.defaultProps = {};

export default TabContainer;
