import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import counterReducer from "./counter";
import selectedEventItemReducer from './selectedEventItem'
import selectedItemReducer from './selectedItem'
import activeItemsReducer from "./activeItems";
import jsonStructureReducer from "./jsonStructure";
import itemsReducer from "./items";
import userReducer from "./user"

export default configureStore({
    reducer: {
        counter: counterReducer,
        selectedEventItem: selectedEventItemReducer,
        selectedItem: selectedItemReducer,
        activeItems: activeItemsReducer,
        jsonStructure: jsonStructureReducer,
        items: itemsReducer,
        user: userReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
