import {createSlice} from "@reduxjs/toolkit";
import {retrieveJsonStructure} from "../common/JsonStructureUtils";

/**
 This Redux Store is used to store the Structure of the Entries
 **/

export const jsonStructureSlice = createSlice({
        name: "structure",
        initialState: {
            body: []
        },
        reducers: {
            addToStructureBody: (state, action) => {
                console.log('NEED LOGIC HERE TO ADD TO BODY')
                //TODO update this when we need to update API as well

            },
            setStructureBody: (state, action) => {
                state.body = action.payload;
            },
            deleteStructureItem: (state, action) => {

                state.body.splice(action.payload, 1);
            }
        }

    }
);

// async function initState() {
//     await retrieveJsonStructure()
// }

// Action creators are generated for each case reducer function
export const {addToStructureBody, setStructureBody, deleteStructureItem } = jsonStructureSlice.actions;

export default jsonStructureSlice.reducer;
