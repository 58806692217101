import React from 'react';
import styles from './FooterBar.module.css';
import {Bug, BugIcon, Camera} from 'lucide-react';
import ModalReport from "../ModalReport/ModalReport";


const FooterBar = () => {
    return (
        <div>
            <div className={styles.FooterBar}>
                <nav className="nav shadow-lg justify-content-end">
                    <div className={styles.bug}>
                        <Bug size={48}
                             data-bs-toggle="modal"
                             data-bs-target="#modalReport"
                        />
                    </div>
                </nav>
            </div>
            <ModalReport/>

        </div>
    )
};

FooterBar.propTypes = {};

FooterBar.defaultProps = {};

export default FooterBar;
