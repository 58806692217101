import React, {useState} from 'react';
import styles from './Tab.module.css';
import {useDispatch, useSelector} from "react-redux";
import {setActiveItem, deleteItem} from "../../redux/activeItems";
import {updateSelectedItem} from "../../redux/selectedItem";
import "./CustomTabCSS.css";


const Tab = (props) => {

    const activeItems = useSelector((state) => state.activeItems.items);
    const selectedItem = useSelector((state) => state.selectedItem)
    const dispatch = useDispatch();


    // console.log('active items: ' + activeItems.length);

    let list = activeItems.map(function (item) {
        let isActive = ''
        if (item.active) {
            isActive = 'active';
            // if(localActiveId != ''){
            //     setLocalActiveId(item.id) // checks that its not already been initialize to prevent infinite loop
            // }
            // console.log('Active Id: ' + item.id);
        }

        return <li className="nav-item" role="presentation" key={item.id}>
            <div className={`nav-link ${styles.removeTextHighlight} ${styles.tab}  ${isActive} `} id={`${item.id}`}
                 data-bs-target={`#${item.name}`} type="button" role="tab" aria-controls={`${item.name}`}
                 aria-selected="true" onClick={(e) => onClickFunction(e)}
                 onMouseOver={(e) => onMouseOverFunction(e)}>
                {item.name}.{item.ext}

                <button type="button" className={`btn-close ${styles.closeBtn}`} aria-label="Close"
                        onClick={(e) => closeTab(e)}></button>
            </div>
        </li>;
    })

    return (
        <div className={styles.Tab}>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {list}
            </ul>
        </div>
    )


    function onClickFunction(e) {
        dispatch(setActiveItem(e.target.id))
        let obj = getActiveItem(e.target.id);
        dispatch(updateSelectedItem(obj))
    }

    function getActiveItem(id) {
        for (let i = 0; i < activeItems.length; i++) {
            let item = activeItems[i];
            if (item.id == id) {
                return {
                    id: item.id,
                    name: item.name,
                    type: item.file,
                    content: item.content,  // replace \\n from backend to \n
                    ext: item.ext,
                    active: true
                }
            }
        }
    }

    function onMouseOverFunction(e) {
        // e.target.backgroundColor  = 'yellow'
    }

    function closeTab(e) {
        e.stopPropagation()
        console.log('Closed Clicked')

        let btnElement = e.target.parentElement;


        for (let i = 0; i < activeItems.length; i++) {
            if (btnElement.id == activeItems[i].id) {

                // Make next correct tab active
                if (wasClosedTabActiveTab(btnElement)) {
                    // Only update Active Tab if closed tab was Active

                    let item = updateActiveTabUponClosingATab(activeItems[i].id, i);
                    //
                    if (activeItems.length > 1) {
                        // When there is more than 1 tab open
                        dispatch(updateSelectedItem(item));
                    } else {
                        dispatch(updateSelectedItem(''));

                    }
                } else {
                    console.log('DELETE THIS ELSE!!!' + btnElement.id)
                }

                // Remove from active list
                console.log('Matched - ' + btnElement.id)
                dispatch(deleteItem(i))

                break;
            }
        }
    }

    function wasClosedTabActiveTab(element) {
        let activeId = getActiveIdFromActiveItems();
        if (element.id == activeId) {
            console.log('It was active')
            return true;
        } else {
            console.log('It was NOT active')
            return false;
        }

    }

    function getActiveIdFromActiveItems() {
        for (let i = 0; activeItems.length; i++) {
            if (activeItems[i].active == true) {
                return activeItems[i].id
            }
        }
        return '';
    }

    function updateActiveTabUponClosingATab(id, index) {
        if (activeItems.length == 1) {
            // Don't make anything active and just set editor to blank
        } else if (activeItems.length == 2) {
            // set only item to active
            if (index == 1) {
                dispatch(setActiveItem(activeItems[0].id));
                return activeItems[0];
            } else {
                dispatch(setActiveItem(activeItems[1].id));
                return activeItems[1];
            }
        } else {
            // ex: 10 items
            if (index > 0) {
                // make index-1 active
                dispatch(setActiveItem(activeItems[index - 1].id));
                return activeItems[index - 1];
            } else {
                // first tab was close, so make first tab (new first tab) active
                dispatch(setActiveItem(activeItems[1].id));
                return activeItems[1];
            }

        }
    }
};

Tab.propTypes = {};

Tab.defaultProps = {};

export default Tab;
