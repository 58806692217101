import React, {useState} from 'react';
import styles from './ContextMenu.module.css';
import {getMatchingJsonObj} from "../../common/utils";
import {useDispatch, useSelector} from "react-redux";
import {deleteStructureItem, setStructureBody} from "../../redux/jsonStructure";
import {getAllChildEntries, removeFromStructure, returnParentNodeOfEntry} from "../../common/utilsV2";
import _ from 'lodash'
import {post} from "../../common/HttpUtils";


const ContextMenu = ({
                         x,
                         y,
                         files,
                         setContextMenu,
                         entryObj,
                         setEntryObj,
                         targetVal
                     }) => {

    const jsonStructure = useSelector((state) => state.jsonStructure.body);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // const [filesToBeDeletedList, setFilesToBeDeletedList] = useState([]);

    function onHover(e) {
        e.target.style.backgroundColor = '#cecece';
    }

    function onLeave(e) {
        e.target.style.backgroundColor = '';
    }

    function selectEntryType(e) {
        console.log("Creating new Entry")
        if (e.target.id === 'newFile') {
            let tempObj = Object.assign({}, entryObj);
            tempObj.type = 'file';
            setEntryObj(tempObj);
        } else if (e.target.id === 'newFolder') {
            let tempObj = Object.assign({}, entryObj);
            tempObj.type = 'folder';
            setEntryObj(tempObj);
        } else {
            console.error('Delete was selected!!!')
        }
    }


    return (
        <div>
            <div className={styles.ContextMenu} style={{top: `${y}px`, left: `${x}px`}}
                 onClick={() => setContextMenu(false)}>
                <ul className="list-group">
                    <li className={`list-group-item ${styles.ContextMenuItem}`} id='newFile'
                        onMouseEnter={(e) => onHover(e)}
                        onMouseLeave={(e) => onLeave(e)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => selectEntryType(e)}>New File
                    </li>
                    <li className={`list-group-item ${styles.ContextMenuItem}`} id='newFolder'
                        onMouseEnter={(e) => onHover(e)}
                        onMouseLeave={(e) => onLeave(e)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => selectEntryType(e)}>New Folder
                    </li>
                    <li className={`list-group-item ${styles.ContextMenuItem}`} onMouseEnter={(e) => onHover(e)}
                        onMouseLeave={(e) => onLeave(e)} onClick={(e) => deleteEntry(e)}>Delete
                    </li>
                </ul>
            </div>
        </div>

    )

    function deleteEntry(e) {
        console.log('Deleted Item')
        let siblingEntries = getMatchingJsonObj(targetVal.id, files)

        if (siblingEntries) {
            for (const index in siblingEntries) {
                if (siblingEntries[index].id === targetVal.id) {
                    let entry = siblingEntries[index];
                    // if entry is a file, delete file ()
                    if (entry.type === 'file') {
                        removeFileEntry(entry);
                    } else {
                        let filesToBeDeletedList = [];
                        iterateAndStoreAllFiles(filesToBeDeletedList, entry.id);
                        // remove all entries from DB
                        removeFileEntriesFromDB(filesToBeDeletedList)
                    }
                }
            }
        } else {
            console.error('Did not find any matching value for Deletion')
        }
    }

    function removeFileEntry(entry) {
        let tempStructure = _.cloneDeep(jsonStructure);
        let updatedStructure = removeFileEntryFromAllSources(tempStructure, entry.id);

        // TODO if tab active, remove tab, and remove it from active list
    }

    function removeFileEntryFromAllSources(structure, targetId) {
        let parentNode = returnParentNodeOfEntry(structure, targetId);
        // let uniqueFileIds = [targetId];

        for (let i = 0; i < parentNode.length; i++) {
            if (parentNode[i].id == targetId) {
                parentNode.splice(i, 1);
                break;
            }
        }

        // Update Json Structure Store
        dispatch(setStructureBody(structure));

        // Remove File Entry from DB
        let targetIdList = [targetId]
        removeFileEntriesFromDB(targetIdList);

        // Update Structure From DB when deleting an entry in context menu
        saveJsonStructure(structure);

        return structure;

    }

    // Used for deletion of an entry
    function saveJsonStructure(structure) {

        let url = process.env.REACT_APP_SAVE_JSON_STRUCTURE_URL;
        let request = {
            uniqueUserId: user.uniqueUserId,
            jsonStructure: JSON.stringify(structure)
        }
        post(url, request);
    }


    function removeFileEntriesFromDB(uniqueFileIdsList) {
        let url = process.env.REACT_APP_REMOVE_FILE_CONTENT_URL;

        let body = {
            uniqueFileIds: []
        }

        for (let element of uniqueFileIdsList) {
            let temp = {
                id: element
            }
            body.uniqueFileIds.push(temp)
        }


        let request = JSON.stringify(body)

        post(url, request)
    }

    function iterateAndStoreAllFiles(filesToBeDeletedList, folderToBeDeletedId) {

        // let temp_filesToBeDeletedList = [];

        let jsonStructureLocal = _.cloneDeep(jsonStructure);
        let obj = getAllChildEntries(filesToBeDeletedList, folderToBeDeletedId, jsonStructureLocal)

        // update DB
        updateJsonStructureInDB(obj.updatedJsonStructure);

        // Save Store
        dispatch(setStructureBody(obj.updatedJsonStructure));

        // Save Active List????


    }

    function updateJsonStructureInDB(structure) {
        saveJsonStructure(structure)
    }
};

ContextMenu.propTypes = {};

ContextMenu.defaultProps = {};

export default ContextMenu;


