import React, {useState} from 'react';
import styles from './ModalReport.module.css';

const ModalReport = () => {
    const [subject, setSubject] = useState('');
    const [textArea, setTextArea] = useState('');

    function handleChange(e, param) {
        if(param == 'subject'){
            setSubject(e.target.value);
            console.log(e.target.value)
        } else if (param == 'textArea'){
            setTextArea(e.target.value);
            console.log(e.target.value)
        }
    }

    function submitReport(){

        // Call API and Save Report
        console.log('Saved')

        // Clear Values
        setSubject('');
        setTextArea('')
        document.getElementById('subjectForm').value = ''
        document.getElementById('bodyForm').value = ''


    }

    return (
        <div>
            <div className="modal fade" id="modalReport" tabindex="-1" aria-labelledby="modalReportLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className={`modal-header ${styles.modalHeader}`}>
                            <h5 className="modal-title" id="modalReportLabel">Report Bug / Make Suggestion</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className={`form-group row ${styles.formGroup}`}>
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Subject:</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="subjectForm"
                                             placeholder="" onChange={(e)=>handleChange(e, 'subject')}/>
                                    </div>
                                </div>
                                <div className={`form-group row ${styles.formGroup}`}>
                                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Body:</label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control" id="bodyForm"
                                                  rows="3" onChange={(e) => handleChange(e, 'textArea')}></textarea>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={submitReport} data-bs-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

ModalReport.propTypes = {};

ModalReport.defaultProps = {};

export default ModalReport;
