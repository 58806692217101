import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './DirectoryRoot.module.css';
import Directory from "../Directory/Directory";


const DirectoryRoot = (props) => {

    return (
        <div className={styles.DirectoryRoot}>
            <Directory contextMenu={props.contextMenu}
                       setContextMenu={props.setContextMenu}
                       userState={props.userState}
            />
        </div>

    )
};

DirectoryRoot.propTypes = {};

DirectoryRoot.defaultProps = {};

export default DirectoryRoot;
