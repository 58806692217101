import {createSlice} from "@reduxjs/toolkit";
import {retrieveFileContent} from "../common/HttpUtils";

/**
 This Redux Store is to store the current selected Item

 id:
 name:
 type:
 content:
 ext:

 **/
export let selectedItemSlice = createSlice({
    name: "selectedItem",
    initialState: {
        id: 'someId',
        name: 'someName',
        type: 'someType',
        content: 'someContent',
        ext: 'txt'
    },
    reducers: {
        updateSelectedItem: (state, action) => {
            state.id = action.payload.id;
            state.type = action.payload.type;
            state.content = action.payload.content;
            state.ext = action.payload.ext;

        },
        updateContent: (state, action) => {
            state.content = action.payload;
        }
    }
})

export const {updateSelectedItem, updateContent} = selectedItemSlice.actions;

export default selectedItemSlice.reducer;