import {createSlice} from "@reduxjs/toolkit";

// This slice has a state that determines which is the active (highlighted)
// file/folder on the directory (left panel)
export let selectedEventItemSlice = createSlice({
    name: "selectedEventItem",
    initialState: {
        selectedEventItem: null
    },
    reducers: {
        updateSelectedEventItem: (state, action) => {
            state.selectedEventItem = action.payload;
        }
    }
})

export const {updateSelectedEventItem} = selectedEventItemSlice.actions;

export default selectedEventItemSlice.reducer;