import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavBar.module.css';
import {ChevronDown, LogOut} from "react-feather";

const NavBar = (props) => (
    <div className={styles.NavBar}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light  shadow " >
            <div className="container-fluid">
                <span className="navbar-brand" href="#">Hello {props.name}! </span>
                <div className="" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <form className="d-flex">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    Settings
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="#">Config</a></li>
                                    <li><a className="dropdown-item" href="#">Another</a></li>
                                    <li>
                                        <hr className="dropdown-divider"/>
                                    </li>
                                    <li className=''>
                                        <a className="dropdown-item" onClick={props.signOut} href="#">
                                            <LogOut color="black" size={14}/>
                                            &nbsp; Log Out</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </nav>
    </div>
);

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;
