import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./redux/store";

import '@aws-amplify/ui-react/styles.css';
import {Provider} from "react-redux";
import {Amplify, Auth } from "aws-amplify";
import config from './aws-exports'
import {AmplifyProvider} from "@aws-amplify/ui-react";

Amplify.configure(config)

// createStore(allReducer);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <AmplifyProvider>
            {/*<React.StrictMode>*/}
            <App/>
            {/*</React.StrictMode>*/}
        </AmplifyProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
