import React, {useEffect, useRef, useState} from "react";
import "jsoneditor/dist/jsoneditor.css";
import "./CustomAceCSS.css";


import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-xcode";
import 'ace-builds/src-min-noconflict/ext-searchbox';
// import 'ace-builds/src-min-noconflict/ext-beautify';
import _ from 'lodash'


import {useDispatch, useSelector} from "react-redux";
import {updateSelectedItem, updateContent} from "../../redux/selectedItem";
import toast, {Toaster} from "react-hot-toast";
import {updateContentOfItem, updateItem, updateUndoManagerOfItem} from "../../redux/items";
import {post} from "../../common/HttpUtils";
import activeItems from "../../redux/activeItems";
import {convertExtToMode} from "../../common/convertExtension";


const TextEditor = (props) => {
    const activeItemsList = useSelector((state) => state.activeItems.items);
    const itemsList = useSelector((state) => state.items.body);
    const [localEditor, setEditor] = useState(null);

    let activeItem = '';


    for (const activeItem1 of activeItemsList) {
        if (activeItem1.active) {

            for (const item of itemsList) {
                if (item.id === activeItem1.id) {
                    activeItem = item;
                    break;
                }
            }
            break;
        }

    }


    let mode = '';
    if (activeItem.id) {
        mode = convertExtToMode(activeItem.ext)
        console.log('mode: ' + mode);
    }
    const dispatch = useDispatch();

    function onChange(newValue) {
        dispatch(updateContent(newValue))   // Is this one needed?

        let contentObj = {
            id: activeItem.id,
            content: newValue
        }
        dispatch(updateContentOfItem(contentObj))

        // TODO this is inefffecient!!
        const locEditor = _.cloneDeep(localEditor.session.$undoManager);

        let sessionStackObj = {
            id: activeItem.id,
            undoManager: locEditor
        }
        dispatch(updateUndoManagerOfItem(sessionStackObj))
    }

    function onBeforLoad(ace) {
        console.log('ON BEFORE LOAD ------')

    }

    function onLoad(editor) {
        setEditor(editor);
        console.log('ON LOAD ------')

    }

    function onSelectionChange(selection) {
    }

    function saveContent(e) {
        console.log(e.ctrlKey && e.key === 's')
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault()

            let toastId = toast.loading('Loading!',)
            setFileContent()
            console.log('Success???')
            setTimeout(function () {
                toast.success('Saved!', {
                    id: toastId,
                    duration: 2000
                })
            }, 1000);

        }
    }

    async function setFileContent() {
        // REACT_APP_SAVE_FILE_CONTENT
        let request = {
            id: activeItem.id,
            name: activeItem.name,
            content: activeItem.content,
            ext: activeItem.ext
        }
        let url = process.env.REACT_APP_SAVE_FILE_CONTENT_URL
        let response = await post(url, request)
        console.log('yo: ' + response)

        dispatch(updateSelectedItem(request)) // TODO Do i need this?
        return response;

    }

    function onFocus(event, editor) {

        console.log('on Focus Console Log')

        for (const activeItem1 of activeItemsList) {
            if (activeItem1.active) {

                for (const item of itemsList) {
                    if (item.id === activeItem1.id) {
                        if (item.undoManager) {
                            editor.session.$undoManager.$undoStack = item.undoManager.$undoStack;
                            editor.session.$undoManager.$redoStack = item.undoManager.$redoStack;
                        } else {
                            console.log('Undo Manager Reset')
                            editor.session.$undoManager.reset();

                        }
                        break;
                    }
                }
                break;
            }
        }

        // editor.session.$undoManager.$undoStack = []

    }

// TODO https://stackoverflow.com/questions/60543774/ace-editor-with-reactjs-undo-functionality

    return (<div onKeyDown={(e) => saveContent(e)}>
            <Toaster/>

            <AceEditor
                placeholder="Type something here :)"
                mode={mode}
                theme="xcode"
                name={activeItem.id}
                onBeforeLoad={onBeforLoad}
                onLoad={onLoad}
                onChange={onChange}
                onSelectionChange={onSelectionChange}
                fontSize={14}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={false}
                height={window.innerHeight - 200 + 'px'}
                width={'95%'}
                value={activeItem.content}
                // value={currValue}
                cursorStart={1}
                onFocus={onFocus}
                setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    // selectionStyle:'line'
                }}
                commands={
                    [

                    ]}

            />
        </div>
    )

    // function convertExtToMode(ext) {
    //     switch (ext.toString().toLowerCase()) {
    //         case 'java':
    //             return 'java'
    //             break;
    //         case 'js':
    //             return 'javascript'
    //             break;
    //         case 'html':
    //             return 'html'
    //             break;
    //         case 'css':
    //             return 'css'
    //             break;
    //         case 'sql':
    //             return 'sql'
    //             break;
    //         default:
    //             return 'text'
    //     }
    // }
};

TextEditor.propTypes = {};

TextEditor.defaultProps = {};

export default TextEditor;
