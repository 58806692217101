import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import JsonRoot from "./components/JsonRoot/JsonRoot";

import {withAuthenticator} from "@aws-amplify/ui-react";
import React, {useEffect, useState} from "react";
import {post} from "./common/HttpUtils";
import {setStructureBody} from "./redux/jsonStructure";
import {setUser} from "./redux/user";
import {useDispatch} from "react-redux";
import NavBar from "./components/NavBar/NavBar";
import FooterBar from "./components/FooterBar/FooterBar";
import ModalReport from "./components/ModalReport/ModalReport";

function App({signOut, user}) {
    const dispatch = useDispatch();
    const [userState, setUserState] = useState(null);

    useEffect(() => {
        initApi().then(r => console.log('INIT API '))
    }, []);

    return (
        <div className="App">
            {/*<h1>Hello {user.attributes.name}</h1>*/}
            {/*<button onClick={signOut}>Sign out</button>*/}
            <NavBar name={user.attributes.name} signOut={signOut}/>
            <JsonRoot userState={userState}/>
            <FooterBar/>
        </div>
    );

    async function initApi() {

        // Save User
        let url = process.env.REACT_APP_RETRIEVE_USER_URL;
        let request = {
            name: user.attributes.name,
            email: user.attributes.email,
            uniqueUserId: user.attributes.sub
        }
        await post(url, request);
        dispatch(setUser(request));
        setUserState(request);

        // Retrieve JSON Structure
        let jsonStructureURL = process.env.REACT_APP_RETRIEVE_JSON_STRUCTURE_URL;
        let jsonStructureReq = {
            name: user.attributes.name,
            email: user.attributes.email,
            uniqueUserId: user.attributes.sub
        }
        let response = await post(jsonStructureURL, jsonStructureReq);
        dispatch(setStructureBody(response));
    }
}

// export default App;
export default withAuthenticator(App);
  