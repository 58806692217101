import {createSlice} from "@reduxjs/toolkit";

/**
 This Redux Store is used to store the active tabs

 active:   OK
 content:  ????
 ext:         OK
 id:       OK
 name:     OK
 type:     ????
 session:  ?????
 **/
export const activeItemsSlice = createSlice({
    name: "activeItems",
    initialState: {
        items: []

    },
    reducers: {

        addToActiveItems: (state, action) => {
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].active == true) {
                    state.items[i].active = false;
                }
            }
            state.items.push(action.payload);

        },
        setActiveItem: (state, action) => {
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].id == action.payload) {
                    state.items[i].active = true;
                } else {
                    state.items[i].active = false;

                }

            }
        },
        deleteItem: (state, action) => {
            state.items.splice(action.payload, 1)
            console.log('test: ' + state.items.length);
        },


    }
});

// Action creators are generated for each case reducer function
export const {
    setActiveItem,
    addToActiveItems,
    deleteItem
} = activeItemsSlice.actions;

export default activeItemsSlice.reducer;
