import React, {useState} from 'react';
import styles from './Editor.module.css';
import TextEditor from "../TextEditor/TextEditor";
import FileTabs from "../FileTabs/FileTabs";
import TabContainer from "../TabContainer/TabContainer";
import {useSelector} from "react-redux";

const Editor = () => {

    const activeItems = useSelector((state) => state.activeItems.items);

    return (
        <div className={styles.Editor}>
            <TabContainer/>
            {
                activeItems.length > 0 ? <TextEditor/> : ''
            }
        </div>

    )
};

Editor.propTypes = {};

Editor.defaultProps = {};

export default Editor;
