import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";

export function post(url, reqBody) {
    return axios.post(url
        , reqBody
        , {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(function (response) {
            // console.log('response status: ' + response.status);
            // console.log('responseData response: ' + response.data.response);
            return response.data;
        })
}

export const retrieveFileContent = createAsyncThunk('selectedItem/getContent', async (reqObj) => {
        return post(reqObj.url, reqObj.reqBody);
    }
)