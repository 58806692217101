import React, {useState} from 'react';
import styles from './DirectoryFoldersLoad.module.css';
import {ChevronDown, ChevronRight, FileText, Folder} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import {updateSelectedEventItem} from "../../redux/selectedEventItem";
import {updateSelectedItem, updateContent} from "../../redux/selectedItem";
import {setActiveItem, addToActiveItems} from "../../redux/activeItems";

import {post, retrieveFileContent} from "../../common/HttpUtils";
import {addToItems} from "../../redux/items";

const DirectoryFoldersLoad = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const {selectedEventItem} = useSelector((state) => state.selectedEventItem);
    const activeItems = useSelector((state) => state.activeItems.items);
    const items = useSelector((state) => state.items.body);

    const dispatch = useDispatch();

    if (props.files != null && props.files.type === 'folder') {
        return (
            <div
                className={`content-container folder ${styles.folderTitle} ${styles.container} ${styles.jeremyBtn}`}>
                <div id={props.files.id} className='content type_folder' onClick={(event) => onClickAction(event)}
                     onDoubleClick={(event) => toggleExpand(!isExpanded)} onContextMenu={(event) =>addHighlight(event)}>
                    {isExpanded
                        ? <ChevronDown className={styles.iconGap} color="black" size={14}/>
                        : <ChevronRight className={`${styles.iconGap}`} color="black" size={14}/>
                    }
                    <Folder className={`broken_folder ${styles.iconGap}`} color="orange" size={14}/>
                    {props.files.name}
                </div>

                {
                    isExpanded && props.files.items.map((item, index) => <DirectoryFoldersLoad files={item}
                                                                                               setContextMenu={props.setContextMenu}
                                                                                               key={index}
                                                                                               isFirstElement={false}
                    />)
                }
            </div>
        )
    } else if (props.files != null && props.files.type === 'file') {
        return (
            <>
                <div
                    className={`content-container file ${styles.container} ${styles.jeremyBtn} ${styles.fileContainer}`}
                    onClick={(event) => onClickAction(event)} onContextMenu={(event)=>addHighlight(event)}>
                    <div id={props.files.id} className='content type_file'>
                        <FileText className={styles.iconGap} color="orange" size={14}/>
                        {props.files.name}
                    </div>
                </div>
            </>
        )
    }

    function toggleExpand(value) {
        setIsExpanded(value);
    }

    function onClickAction(event) {
        event.stopPropagation()
        addHighlight(event)
        props.setContextMenu({show: false})
        if(event.target.classList.contains('type_file')){
            makeEntryActive(event.target)
        }

        if (event.target.classList.contains('file')) {
            console.log("A File was Selected!")
        }
    }

    function addHighlight(event) {

        //remove old highlight
        removePreviousHighlight();

        let target = event.target;

        if (!target.classList.contains('content')) {
            // if icons are selected, find its parent content class
            target = findParentContentClass(target);
        }

        updateNewHighlight(target);

    }

    function findParentContentClass(target) {
        if (target.classList.contains('content')) {
            return target;
        }
        return findParentContentClass(target.parentElement);
    }

    function removePreviousHighlight() {
        if (selectedEventItem != null) {
            selectedEventItem.style.background = '';
        }
    }

     function updateNewHighlight(target) {
        target.style.background = '#dcdcdc';
        dispatch(updateSelectedEventItem(target));
    }

    async function makeEntryActive(target){
        // Update Content

        // Check if file exist in items store
        let response = getItemFromItemsStore(target.id);
        if (response == null) {
            let reqObj = {
                url: process.env.REACT_APP_RETRIEVE_FILE_CONTENT,
                reqBody: {
                    id: target.id
                }
            }
            response = await post(reqObj.url, reqObj.reqBody)
        }

        // TODO need to find better way to pass this, since below functions do not need every single one of these fields in the Redux Store
        let obj = {
            id: response.id,
            name: response.name,
            type: 'file',
            content: response.content.replace(/\\n/g, '\n'),  // replace \\n from backend to \n
            ext: response.ext,
            active: true
        }

        // Add to Item Store
        addItemsStoreIfNotExist(obj);

        // Update Selected Item Store
        dispatch(updateSelectedItem(obj))

        // Add/Set active
        addToActiveList(obj);

    }

    function addItemsStoreIfNotExist(obj) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == obj.id) {
                return;
            }
        }
        // let itemClass = new ItemClass()
        dispatch(addToItems(obj));
    }

    function addToActiveList(obj) {

        for (let i = 0; i < activeItems.length; i++) {
            if (activeItems[i].id == obj.id) {
                dispatch(setActiveItem(obj.id))
                return;
            }
        }
        // Update Active Items Store
        dispatch(addToActiveItems(obj));
    }

    function getItemFromItemsStore(targetId) {

        for (let i = 0; i < items.length; i++) {
            if (items[i].id == targetId) {
                return items[i];
            }
        }
        return null;
    }


};


export default DirectoryFoldersLoad;
