import React, {useEffect, useState} from 'react';
import DirectoryFolders from "../DirectoryFolders/DirectoryFolders";
import axios from "axios";
import NewEntryModal from "../NewEntryModal/NewEntryModal";
import style from "./Directory.module.css"
import ContextMenu from "../ContextMenu/ContextMenu";
import {post} from "../../common/HttpUtils";
import {useDispatch, useSelector} from "react-redux";
import {updateItem} from "../../redux/items";
import {retrieveJsonStructure} from "../../common/JsonStructureUtils";
import {addToStructureBody, setStructureBody} from "../../redux/jsonStructure";

const Directory = (props) => {

    const files = useSelector((state) => state.jsonStructure.body)

    let initialEntryObj = {
        name: 'initialName!!!',
        type: 'initialType!!!',
        id: 'initialId!!!!!'
    }
    const [entryObj, setEntryObj] = useState(initialEntryObj);
    const [targetVal, setTargetVal] = useState()

    function handleContextMenu(e) {
        e.preventDefault();
        console.log(e.target)
        const {pageX, pageY} = e
        props.setContextMenu({show: true, x: pageX, y: pageY})
        setTargetVal(e.target)
    }


    return (
        <div className={style.DirectoryFolders} onContextMenu={(e) => handleContextMenu(e)}>
            <DirectoryFolders files={files} setContextMenu={props.setContextMenu}/>
            {props.contextMenu.show && <ContextMenu x={props.contextMenu.x} y={props.contextMenu.y}
                                                    setContextMenu={props.setContextMenu}
                                                    files={files}
                                                    targetVal={targetVal}
                                                    entryObj={entryObj}
                                                    setEntryObj={setEntryObj}
            />}
            <NewEntryModal files={files} entryObj={entryObj} setEntryObj={setEntryObj}
                           targetVal={targetVal} setTargetVal={setTargetVal}/>
        </div>

    )
};


export default Directory;
