export function returnParentNodeOfEntry(array, targetId) {
    let result = iterateArray(array, targetId)

    return result;
}

function iterateArray(array, targetId) {

    if (!array) {
        return null;
    }


    for (let i = 0; i < array.length; i++) {
        if (array[i].id == targetId) {
            return array; // This should return parent node
        } else {

            let result = iterateArray(array[i].items, targetId);
            if (result) {
                return result;
            }
            // Otherwise keep going till you find target Id
        }
    }
}


export function iterateJsonArray(jsonArray, selectedId, entryObj) {
    if (!selectedId) {
        // when entry is added at root level of structure
        jsonArray.push(entryObj);
        return;
    }
    for (let i = 0; i < jsonArray.length; i++) {
        iterateJsonObject(jsonArray[i], selectedId, entryObj, jsonArray)
    }
}

export function iterateJsonObject(jsonObj, selectedId, entryObj, jsonArray) {

    if (jsonObj.id == selectedId) {
        // TODO need to find a way to break recursion when ID is found
        console.log("SUCESSS FOUND MATCHING ID !!!!!!!!!!")
        if (jsonObj.type == 'folder') {
            // TODO below is how to manually add after last file
            jsonObj.items.push(entryObj)

        } else if (jsonObj.type == 'file') {
            jsonArray.push(entryObj)

        }

    } else if (jsonObj.items) {
        // JsonObj is a folderF
        iterateJsonArray(jsonObj.items, selectedId, entryObj)
    }
    // Do Nothing if JsonObj is a file and has no match
}

export function getMatchingJsonObj(targetVal, jsonArray) {
    return iterateJsonArray2(targetVal, jsonArray, '');
}

export function iterateJsonArray2(targetVal, jsonArray, parent) {
    for (let i = 0; i < jsonArray.length; i++) {
        let val = iterateJsonObject2(jsonArray[i], targetVal, jsonArray);
        if (val != null) {
            return val;
        }
    }
}

function iterateJsonObject2(jsonObj, targetVal, parent) {
    if (jsonObj.id == targetVal) {
        // TODO need to find a way to break recursion when ID is found
        return parent;
    } else if (jsonObj.items) {
        return iterateJsonArray2(targetVal, jsonObj.items, parent)
    }
    return null;
}


export function getAllChildEntries(array, folderToBeDeletedId, jsonStructure) {
    let parentNodeSiblings = findTargetFolder(folderToBeDeletedId, jsonStructure);
    console.log('');
    let updatedJsonStructure = '' // this should contain new structure the folder and all children deleted, this is returned to update DB


    for(let i = 0; i< parentNodeSiblings.length; i++){
        if(folderToBeDeletedId == parentNodeSiblings[i].id){

            let parentFolderNode = parentNodeSiblings[i];

            iterateAndSaveChildrenFileIds(array, parentFolderNode)

            console.log('complete1');

            parentNodeSiblings.splice(i, 1);

            console.log('complete2');


        }
    }
    let obj = {
        updatedJsonStructure: jsonStructure,
        listOfChildrenFiles: array
    };
    return obj;

}

function findTargetFolder(targetId, jsonStructure){
   return returnParentNodeOfEntry(jsonStructure, targetId)
}

function iterateAndSaveChildrenFileIds(array, parentNode){
    for(let i = 0; i< parentNode.items.length; i++){
        // iterate each child item
        let item = parentNode.items[i];

        if(item.type == 'file'){
            // Store this item
            array.push(item.id)
        } else{
            // when folder, go into it and iterate
            iterateAndSaveChildrenFileIds(array, item);
        }
    }
}

