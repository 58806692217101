import {createSlice} from "@reduxjs/toolkit";

/**
 This Redux Store is to store Items that have been selected by the user,
 and update its content locally (This only includes files)

 id:
 name:
 type:
 content:
 ext:
 undoStack:[]  // TODO localEditor.session.$undoManager.$undoStack = []
 redoStack:[]  // TODO localEditor.session.$undoManager.redoStack = []
 **/


export const itemsSlice = createSlice({
    name: "items",
    initialState: {
        body: []
    },
    reducers: {

        addToItems: (state, action) => {

            state.body.push(action.payload);

        },
        updateItem: (state, action) => {
            for (let i = 0; i < state.body.length; i++) {
                if (state.body[i].id == action.payload.id) {
                    state.body[i] = action.payload;
                }

            }
        },
        updateContentOfItem: (state, action) => {
            for (let i = 0; i < state.body.length; i++) {
                if (state.body[i].id == action.payload.id) {
                    state.body[i].content = action.payload.content;
                    break;
                }

            }
        },
        updateUndoManagerOfItem: (state, action) => {
            for (let i = 0; i < state.body.length; i++) {
                if (state.body[i].id == action.payload.id) {
                    state.body[i].undoManager = action.payload.undoManager;
                    break;
                }

            }
        },
        deleteItem: (state, action) => {
            state.items.splice(action.payload, 1)
        }
    }
});

/**
 id:
 name:
 type: 'file',  // Do i really need this type?
 content: response.content.replace(/\\n/g, '\n'),  // replace \\n from backend to \n
 ext:

 **/

// Action creators are generated for each case reducer function
export const {
    addToItems,
    updateItem,
    updateContentOfItem,
    updateUndoManagerOfItem,
    deleteItem
} = itemsSlice.actions;

export default itemsSlice.reducer;
