import React, {useState} from 'react';
import styles from "./DirectoryFolders.module.css";
import DirectoryFoldersLoad from "../DirectoryFoldersLoad/DirectoryFoldersLoad";

const DirectoryFolders = (props) => {

    let initHighlightedEvent = {
        event: null
    }

    const [highlightedEvent, setHighlightedEvent] = useState(initHighlightedEvent);

    const [selectedItem, setSelectedItem] = useState();  // Should this contain the UUID? So that we can reference it?
    const [selectedEvent, setSelectedEvent] = useState();


    if (props.files && props.files.length > 0) {
        return (
            props.files.map((item, index) =>
                <div key={index}>
                    <div className={styles.directoryLoadFoldersClass}>
                        <DirectoryFoldersLoad files={item} selectedItem={selectedItem}
                                              highlightedEvent={highlightedEvent}
                            // setHighlightedEvent={setHighlightedEvent}
                            // setSelectedItem={setSelectedItem}
                                              selectedEvent={selectedEvent}
                                              setSelectedEvent={setSelectedEvent}
                                              isFirstElement={true}
                                              setContextMenu={props.setContextMenu}
                                              key={index}
                        />
                    </div>
                </div>
            )

        )
    } else {
        return (
            <div className={styles.emptyStructure}>
                <span>Right click HERE to create folder/file</span>
            </div>)
    }


};

DirectoryFolders.propTypes = {};

DirectoryFolders.defaultProps = {};

export default DirectoryFolders;
